<template>
  <page-list class="page-list__spec">
    <template #header>
      <el-form ref="form" :model="form" :rules="rules" label-width="120px" inline=true>
        <el-form-item label="所属门店：" prop="shopId">
          <el-select
            v-model="form.shopId"
            placeholder="请选择"
            :disabled="mode === 'edit'"
            @change="shopChangeHandle"
            clearable
            filterable
          >
            <el-option
              v-for="(item, index) in shopOptions"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList()">查 询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="addHandle()">添 加</el-button>
        </el-form-item>
      </el-form>
    </template>
    <el-tabs
      type="border-card"
      v-model="activeTab"
      @tab-click="tabChangeHandle"
    >
      <el-tab-pane
        :label="item.label"
        :name="item.value + ''"
        v-for="(item) in categoryChooseOptions"
        :key="item.value + ''"
      >
        <!-- name和type要保持一致 -->
        <!-- <H5>{{JSON.stringify(item)}}</H5>
        <H5>{{activeTab}}</H5> -->
        <el-table
          :data="tableData"
          style="width: 100%"
          border
          stripe
          v-loading="loading"
          element-loading-background="transparent"
          @row-dblclick="editHandle"
          row-key="id"
        >
          <el-table-column
            prop="id"
            label="ID"
            min-width="50"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="shopId"
            label="门店"
            min-width="120"
            header-align="center"
            align="center"
            show-overflow-tooltip
            :formatter="(row) => getShopName(row.shopId)"
          >
          </el-table-column>
          <el-table-column
            prop="categoryInfo.name"
            label="分类名称"
            min-width="120"
            header-align="center"
            align="center"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="title"
            label="产品标题"
            min-width="120"
            header-align="center"
            align="center"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="deviceFirstName"
            label="物联设备一级菜单"
            min-width="150"
            header-align="center"
            align="center"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="flag_text"
            label="主图"
            min-width="60"
            header-align="center"
            align="center"
          >
            <template v-slot="scope">
              <el-image
                v-if="scope.row.image"
                style="width: 30px; height: 30px"
                :src="scope.row.image.split(',')[0]"
                :preview-src-list="scope.row.image.split(',')"
                preview-teleported
              ></el-image>
            </template>
          </el-table-column>
          <el-table-column
            prop="type"
            label="类型"
            min-width="80"
            header-align="center"
            align="center"
            :formatter="(row) => (row.type === '02' ? '产品组' : '产品')"
          >
          </el-table-column>
          <el-table-column
            prop="salesPrice"
            label="最低价"
            min-width="80"
            header-align="center"
            align="center"
            :formatter="(row) => Number(row.salesPrice).toFixed(2)"
          >
          </el-table-column>
          <el-table-column
            prop="stock"
            label="库存"
            min-width="80"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="noBuyYet"
            label="下单未支付"
            min-width="100"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <!-- <el-table-column
            prop="look"
            label="虚拟浏览量"
            min-width="100"
            header-align="center"
            align="center"
          >
          </el-table-column> -->
          <el-table-column
            prop="sales"
            label="虚拟销售量"
            min-width="100"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="realSales"
            label="真实销量"
            min-width="80"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <!-- <el-table-column
            prop="realLook"
            label="真实浏览量"
            min-width="100"
            header-align="center"
            align="center"
          >
          </el-table-column> -->
          <el-table-column
            prop="createtime"
            label="创建时间"
            min-width="150"
            header-align="center"
            align="center"
            :formatter="
              (row) => $moment.unix(row.createtime).format('yyyy-MM-DD HH:mm:ss')
            "
          >
          </el-table-column>
          <el-table-column
            prop="ishotswitch"
            label="热门推荐"
            min-width="80"
            header-align="center"
            align="center"
            fixed="right"
          >
            <template v-slot="scope">
              <el-switch
                v-model="scope.row.ishotswitch"
                active-color="#18bc9c"
                inactive-color="#d2d6de"
                :active-value=1
                :inactive-value=0
                @change="toggleHandle(scope, 'ishotswitch')"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column
            prop="weigh"
            label="权重"
            min-width="60"
            header-align="center"
            align="center"
            fixed="right"
          >
          </el-table-column>
          <el-table-column
            prop="switchFlag"
            label="是否上架"
            min-width="80"
            header-align="center"
            align="center"
            fixed="right"
          >
            <template v-slot="scope">
              <el-switch
                v-model="scope.row.switchFlag"
                active-color="#18bc9c"
                inactive-color="#d2d6de"
                :active-value=1
                :inactive-value=0
                @change="toggleHandle(scope.row, 'switchFlag')"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            header-align="center"
            fixed="right"
            width="180"
          >
            <template v-slot="scope">
              <el-button
                class="icon-btn copy-btn fa fa-copy"
                @click="copyHandle(scope.row)"
                >复制</el-button
              >
              <el-button
                class="icon-btn fa fa-pencil"
                type="primary"
                @click="editHandle(scope.row)"
              >
                <!-- 编辑 -->
              </el-button>
              <el-button
                class="icon-btn fa fa-trash"
                type="danger"
                @click="delHandle(scope.row)"
              >
                <!-- 删除 -->
              </el-button>
              <el-button
                class="icon-btn promotion-btn fa fa-gratipay"
                @click="promotionHandle(scope.row)"
                >满促</el-button
              >
              <!-- 非产品组且启用规格的才能设定互斥 -->
              <el-button
                v-if="scope.row.type === '01' && scope.row.useSpec === 1"
                class="icon-btn alternative-btn fa fa-exchange"
                @click="alternativeHandle(scope.row)"
                >规格互斥</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <pagination
          v-model="paginationData"
          :total="total"
          @change="getList"
        ></pagination>
      </el-tab-pane>
    </el-tabs>
  </page-list>
</template>

<script>
import { cloneDeep } from "lodash";
import { USER_STATUS } from "@/helpers/constants";
import { listAll } from "@/apis/unidrink/category";
import { msgErr, msgSuc } from "@/helpers/message";
import { getShopList } from "@/apis/auth";
import {
  getProductList,
  deleteProduct,
  toggleProduct,
} from "@/apis/unidrink/product";
const calls = [];
export default {
  props: {
    type: {
      type: String,
      default: "",
    },
    shopOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      USER_STATUS: USER_STATUS,
      categoryChooseOptions: [],
      shopId: "",
      tableData: [],
      loading: false,
      initForm: {},
      activeTab:"",
      form: {},
      total: 0,
      paginationData: {},
    };
  },
  emits: ["add", "edit", "copy", "alternative", "promotion"],
  mounted() {},
  methods: {
    getShopName(shopId) {
      const find = this.shopOptions.find((item) => {
        return item.id === Number(shopId);
      });

      return find ? find.name : "";
    },
    editHandle(row) {
      this.$emit("edit", row);
    },
    copyHandle(row) {
      const copyData = cloneDeep(row);
      delete copyData.id;
      this.$emit("copy", copyData);
    },
    getList() {
      this.loading = true;
      if(this.shopId != this.form.shopId){
        this.activeTab = "0";
      }
      getProductList({
        ...this.form,
        ...this.paginationData,
        categoryId: this.activeTab === "0" ? "" : this.activeTab,
      })
        .then(({ code, result, message }) => {
          if (code !== "0000") {
            msgErr(message);
            return;
          }
          const { list, total } = result;
          this.tableData = list;
          this.total = total;
          this.refreshCategoryHandle(this.form.shopId);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    tabChangeHandle({ props }) {
      const { name } = props;
      console.log("tabChangeHandle", name);
      this.activeTab = name;
      this.$nextTick(()=>{
        this.getList();
      });
    },

    addHandle() {
      this.$emit("add");
    },

    /**
     * 规格互斥
     * @param {*}} row
     */
    alternativeHandle(row) {
      this.$emit("alternative", cloneDeep(row));
    },

    /**
     * 满促
     * @param {*}} row
     */
    promotionHandle(row) {
      this.$emit("promotion", cloneDeep(row));
    },

    delHandle(row) {
      this.$confirm("确定删除此项？", "温馨提示").then(() => {
        deleteProduct(row.id)
          .then(({ code, message }) => {
            if (code === "0000") {
              msgSuc("操作成功！");
            } else {
              msgErr(message);
            }
          })
          .finally(() => {
            this.getList();
          });
      });
    },

    toggleHandle(row, key) {
      console.log("toggleHandle",row,key)
      toggleProduct(row, key)
        .then(({ code, message }) => {
          if (code === "0000") {
            msgSuc("操作成功！");
          } else {
            msgErr(message);
          }
        })
        .finally(() => {
          this.getList();
        });
    },

    refreshCategoryHandle(shopId){

      console.log("refreshCategoryHandle",shopId);
      if(this.shopId == shopId){
        return;
      }
      this.categoryChooseOptions.splice(0,this.categoryChooseOptions.length);
      this.categoryChooseOptions.push({ label: "全部", value: "0" });
      this.shopId = shopId;
      if(shopId){
        listAll({shopId:shopId}).then(({ code, result, message }) => {
          if (code !== "0000") {
            msgErr(message);
            return;
          }
          const { list, total } = result;
          this.categoryChooseOptions.push(...list.map((row) => {
            return {
              label: row.name.replace(/[&nbsp;|└]/g, ""),
              value: row.id,
            };
          }));
          this.activeTab = "0";
        });
      }
    },
  },
      // background-color: #e8edf0;
};
</script>

<style lang="scss" scoped>

.panel-page_container {
  background: #ffffff;
  display: flex;
  flex-direction: column;

  .panel-page_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
  }
}
.copy-btn {
  background-color: #3498db;
  color: #ffffff;
  width: 60px;
}

.promotion-btn {
  background-color: #3498db;
  // background-color: #00d6b9;
  color: #ffffff;
  width: 60px;
}

.alternative-btn {
  // background-color: #ff0054;
  background-color: #f56c6c;
  color: #ffffff;
  width: 76px;
}

.el-tabs--border-card{
  box-shadow: 0 0px 0px 0 rgb(0 0 0 / 12%), 0 0 6px 0 rgb(0 0 0 / 4%);
}
</style>
